import React from "react";
import * as styles from "./styles.module.scss";
import { Image } from "../../../hooks/gatsbyImg";

export default function index({ img, title, desc, url }) {
  return (
    <div className={styles.contentService}>
      <div className={styles.img}>
        <Image filename={img} />
      </div>

      <h2>{title}</h2>
      <div className={styles.desc}>
        <p>{desc}</p>
     
      </div>
    </div>
  );
}