export const posts = [
  {
    img: "wizzy2.webp",
    title: "Onboarding programs",
    desc: "With MASTER WiZR Onboarding Program, you can directly integrate your existing presentation material, ensuring optimal results and impact.",
    url: "#",
  },
  {
    img: "wizzy4.webp",
    title: "Graphic and UX Design Services",
    desc: "Our full service branding solutions also include logo work, 3D visualizations, graphic productions, and branding manuals.",
    url: "#",
  },
  {
    img: "wizzy3.webp",
    title: "Follow-up programs",
    desc: "MASTER WIZR is an intelligent business pitch tool that gets smarter over time. Using it regularly reduces the chances of a lapse in consistency or follow-through.",
    url: "#",
  },
  {
    img: "wizzy6.webp",
    title: "Video Development",
    desc: "We have digitized and facilitated communication through a variety of video concepts and solutions with over 1500 Video Debriefs archived for you.",
    url: "#",
  },
  {
    img: "wizzy5.webp",
    title: "CSR",
    desc: "The purpose of MASTER WiZR is to deliver business-to-business solutions to individuals, small businesses and enterprises.",
    url: "#",
  },
  {
    img: "acini1.webp",
    title: "Owners",
    desc: "Founded in Oslo, Norway, Master WiZR is owned by Core20 Consulting Inc and Acini As. Core20 has worked on thousands of communication projects.",
    url: "#",
  },
];
