import React from "react";
import * as style from './style.module.scss'

export default function index() {
  return (
    <div className={style.allinone}>
      <h1>Excellence in Communications</h1>
      <p>
      We provide all your graphic and communication services in one place, so you can coordinate your pitch efforts and ensure your communications are consistent.
      </p>
    </div>
  );
}
